import React from 'react'
import ThankyouModal from '../../layouts/ThankyouModal'
import { useEffect } from 'react'
import { setFormData } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Thankyou = () => {
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const storedUserString = localStorage.getItem("dsaUser") || "";
  const parsedDsaData = storedUserString ? JSON.parse(storedUserString) : "";
  useEffect(() => {
    localStorage.removeItem('dsaUser')
      dispatch(
        setFormData({
          leadId: "",
          stage: "",
          contactNo: "",
          termsAndCondition: true,
          loan: {
            reason: null,
            amount: 1000,
            tenure: 1,
          },
  
          panCard: "",
          emailId: "",
  
          employmentType: "",
  
          employmentDetails: {
            companyTypeId: "",
            industryTypeId: "",
            companyName: "",
            designation: "",
            address: "",
            pinCode: "",
            countryId: "",
            stateId: "",
            cityId: "",
            yearsWorkingIn: "",
            businessTypeId: "",
            professionTypeId: "",
            turnover: "",
            monthlyProfit: "",
            income: "",
            salaryMode: "",
            bankId: "",
            currentAccountBankId: "",
            savingAccountBankId: "",
            businessOwnedId: "",
          },
          personalInfo: {
            emailId: "",
            firstName: "",
            lastName: "",
            genderId: "",
            dateOfBirth: "",
            qualificationId: "",
            maritalStatus: "",
          },
          address: {
            addressLine1: "",
            addressLine2: "",
            pinCode: "",
            countryId: "",
            stateId: "",
            cityId: "",
            residenceTypeId: "",
            yearsLivingIn: "",
          },
          finance: {
            panCard: "",
          },
          others: {
            totalEmiPaidMonthly: 0,
            interestedToGetCreditCard: true,
          },
        })
      );
    }, []);

    useEffect(() => {
      const handleBackButton = (event) => {
        event.preventDefault();
        // navigate('/');
        window.location.href=`/?id=${btoa(parsedDsaData?.dsaId)}`
      };
  
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', handleBackButton);
  
      return () => {
        window.removeEventListener('popstate', handleBackButton);
      };
    }, [navigate]);
  
  return (
    <div><ThankyouModal/></div>
  )
}

export default Thankyou