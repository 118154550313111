import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../getRegistered/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGetFormData,
  selectIsLoading,
} from "../../redux/selectors";
import Loader from "../../layouts/Loader/Index";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { GetIPAddress, anonymousUser } from "../../constant/services/credMudra";
import Bowser from "bowser";
import { setFormData, setIsLoading } from "../../redux/actions";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";
import { PublicDsaStatus } from "../../constant/services/credMudra/users";
import { Box } from "@mui/material";
const GetRegistered = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formData = useSelector(selectGetFormData);
  const isLoading = useSelector(selectIsLoading);
  const anonymousId = sessionStorage.getItem("anonymousId");

  const currentPath = location.pathname;
  const getQueryParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };
  const dsaId = atob(getQueryParam("id"));
  console.log(dsaId,'dsaid');

  useEffect(() => {
    if (dsaId) {

    getDsaDetails();

    }else{
      navigate("/not-found");
    }
  }, []);

  const getDsaDetails = async () => {
    dispatch(setIsLoading(true));
    const data = {
      dsaId: dsaId,
    };
    const res = await PublicDsaStatus(data);
    if (res?.data?.data === "Dsa not found with provided dsaId") {
      localStorage.removeItem('dsaUser')
      dispatch(setIsLoading(false));
      navigate("/not-found");
    } else {
      const dsaUserString = JSON.stringify(res?.data?.data);
      localStorage.setItem("dsaUser", dsaUserString);
      dispatch(setIsLoading(false));
    }
  };

  const extraDetails = async () => {
    const ip = await GetIPAddress();
    const browser = Bowser.getParser(navigator.userAgent);

    console.log(browser?.parsedResult);
    let position = null;
    try {
      position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    } catch (error) {
      console.log(error);
    }
    dispatch(
      setFormData({
        extras: {
          ...formData.extras,
          browser:
            browser?.parsedResult.browser.name +
            " " +
            browser?.parsedResult.browser.version,
          operatingSystem: browser?.parsedResult.os.name,
          ipAddress: ip,
          userAgent: "",
          location: "",
          timestamp: position?.timestamp,
        },
      })
    );
  };

  const getAnonymousUserId = async () => {
    const ip = await GetIPAddress();
    const postUtmsData = JSON.parse(Cookies.get("utms"));
    const utm_id = getQueryParam("utm_id");
    const utm_url = getQueryParam("utm_url");
    const utm_source = getQueryParam("utm_source");
    const utm_medium = getQueryParam("utm_medium");
    const utm_campaign = getQueryParam("utm_campaign");
    const utm_term = getQueryParam("utm_term");
    const utm_content = getQueryParam("utm_content");

    const browser = Bowser.getParser(navigator.userAgent);
    var position = "";

    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        position = pos;
      },
      (err) => {
        console.log(`Error getting location: ${err.message}`);
      }
    );
    const data = {
      utm: {
        id: postUtmsData.id || utm_id,
        url: postUtmsData.url || utm_url,
        source: postUtmsData.source || utm_source,
        medium: postUtmsData.medium || utm_medium,
        campaign: postUtmsData.campaign || utm_campaign,
        term: postUtmsData.term || utm_term,
        content: postUtmsData.content || utm_content,
        clickId: "",
      },
      extra: {
        browser:
          browser?.parsedResult.browser.name +
          " " +
          browser?.parsedResult.browser.version,
        operatingSystem: browser?.parsedResult.os.name,
        ipAddress: ip,
        timestamp: "",
        userAgent: "",
        location: position?.timestamp,
      },
      path: currentPath,
    };
    const res = await anonymousUser(data);
    sessionStorage.setItem("anonymousId", res?.data?.data?.anonymousId);
  };
  const set_utm = () => {
    const utm_id = getQueryParam("utm_id");
    console.log(utm_id,'utm_id');
   
    const utm_url = getQueryParam("utm_url");
    const utm_source = getQueryParam("utm_source");
    const utm_medium = getQueryParam("utm_medium");
    const utm_campaign = getQueryParam("utm_campaign");
    const utm_term = getQueryParam("utm_term");
    const utm_content = getQueryParam("utm_content");
    console.log(utm_url,'utm_url');
    console.log(utm_source,'utm_source');
    console.log(utm_campaign,'utm_campaign');

    //setting utms in cookies
    const utmsData = Cookies.get("utms");
    if (!utmsData) {
      Cookies.set(
        "utms",
        JSON.stringify({
          id: utm_id,
          url: utm_url,
          source: utm_source,
          medium: utm_medium,
          campaign: utm_campaign,
          term: utm_term,
          content: utm_content,
          clickId: "",
        }),
        { expires: 30 }
      );
    } else if (utmsData && utm_source !== null) {
      Cookies.set(
        "utms",
        JSON.stringify({
          id: utm_id,
          url: utm_url,
          source: utm_source,
          medium: utm_medium,
          campaign: utm_campaign,
          term: utm_term,
          content: utm_content,
          clickId: "",
        }),
        { expires: 30 }
      );
    }
    const postUtmsData = JSON.parse(Cookies.get("utms"));
    dispatch(
      setFormData({
        utm: {
          id: postUtmsData.id || utm_id,
          url: postUtmsData.url || utm_url,
          source: postUtmsData.source || utm_source,
          medium: postUtmsData.medium || utm_medium,
          campaign: postUtmsData.campaign || utm_campaign,
          term: postUtmsData.term || utm_term,
          content: postUtmsData.content || utm_content,
          clickId: "",
        },
      })
    );
  };

  useEffect(() => {
    extraDetails();
    set_utm();
    if (!anonymousId) {
      getAnonymousUserId();
    }
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" , justifyContent:"space-between" , height:"100vh" }}>
        <Header />
        <Helmet>
        <title>Dsa Credmudra</title>
      </Helmet>
        <div className="container">
          <div
            className="row"
            style={{
              paddingTop: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="col-md-12 col-lg-6 col-xl-5 form-section-col">
              {/**-----formSection------------*/}
              <div className="form-div">
                <div
                  className="form-section current"
                  style={{ position: "relative" }}
                  data-step="mobile"
                >
                  <section id="mobile-no">
                    <div className="container">
                      <div
                        className="row"
                        style={{
                          minHeight: "20vh",
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "50px",
                        }}
                      >
                        {isLoading === true ? <Loader /> : <Outlet />}
                      </div>
                    </div>
                  </section>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Box>
    </>
  );
};

export default GetRegistered;
