import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GetRegistered from "../views/getRegistered";
import VerifyUser from "../components/FormComponents/Verify/VerifyUser";
import UserNumber from "../components/FormComponents/UserNumber/UserNumber";

import {
  Loan,
  EmployeType,
  Salaried,
  SelfEmployedDetails,
  PersonalDetails,
  AddressDetails,
  OtherDetails,
  Submit,
  Offers,
  Marketplace,
} from "../components/index";
import NotFound from "../views/NotFound";
import Thankyou from "../views/thankyou";
import Layout from "../layouts";
const RouterComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GetRegistered />}>
          <Route index element={<UserNumber />} />
          <Route path="/verify-user" element={<VerifyUser />} />
          <Route path="/loan-details" element={<Loan />} />
          {/* <Route path="/offers" element={<Offers />} /> */}
          <Route path="/thankyou" element={<Thankyou />} />
          {/* <Route
            path="/marketplace"
            // element={<Marketplace classname={"welcomeform"} />}
          /> */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default RouterComponent;
