import React from "react";
import "../../assets/scss/components/credmudra_megamenu.scss";
import { Box, Typography, useMediaQuery } from "@mui/material";

const Header = () => {
  const storedUserString = localStorage.getItem("dsaUser") || "";
  const sizeWidth = useMediaQuery("(max-width: 477px)");

  return (
    <>
      <header
        style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "black",
            textAlign: "center",
            height: "50px",
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            "@media (max-width: 477px)": {
              p: 0.5,
            },
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "23px",
              fontWeight: 900,
              textTransform: "uppercase",
              "@media (max-width: 477px)": {
                fontSize: "13px",
              },
            }}
          >
            {storedUserString && JSON.parse(storedUserString)
              ? JSON.parse(storedUserString)?.name
              : ""}
          </Typography>
          <span
            style={{
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              flexDirection: sizeWidth && "column",
              justifyContent: "space-between",
              gap: sizeWidth ? "" : "10px",
            }}
          >
            <Typography sx={{ fontSize: "13px", color: "white" }}>
              <i>Powered By</i>
            </Typography>
            <img
              style={{
                height: sizeWidth ? "24px" : "33px",
              }}
              src={
                process.env.PUBLIC_URL + "/assets/images/credmudra_logo_new.png"
              }
              alt=""
            />
          </span>{" "}
        </Box>
      </header>
    </>
  );
};

export default Header;
