import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  TextField,
  CssBaseline,
  Grid,
  InputBase,
  Box,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import bankTransfer from "../../../assets/Images/bank-transfer.svg";
import cash from "../../../assets/Images/cash.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setBankListData,
  setBusinessListData,
  setBusinessOwnedListData,
  setCompanyAddressData,
  setCompanyTypeListData,
  setFormData,
  setGenderTypesListData,
  setIndustryTypesListData,
  setIsLoading,
  setPersonalAddressData,
  setQualificationsListData,
  setResidenceTypesListData,
  setEmployType,
  setPinCodeError,
  setLenderDetails,
  setLoanReasonListData,
} from "../../../redux/actions";
import {
  selectBusinessOwnedList,
  selectBusinessTypeList,
  selectCompanyAddress,
  selectCompanyTypeList,
  selectGenderTypesList,
  selectGetBankList,
  selectGetFormData,
  selectIndustryTypeIdList,
  selectLoanReasonList,
  selectLoanType,
  selectPersonalAddress,
  selectPinCodeError,
  selectQualificationsList,
  selectResidenceTypesList,
} from "../../../redux/selectors";
import {
  CheckEligibility,
  GenerateLead,
  LeadStatus,
  dropOff,
  getPrePopulateFormData,
} from "../../../constant/services/credMudra/users";
import {
  getBanks,
  getBusinessOwned,
  getBusinessTypes,
  getCompanyTypes,
  getGenderTypes,
  getIndustryTypes,
  getLoanReasons,
  getQualifications,
  getResidenceTypes,
} from "../../../constant/services/credMudra/core";
import {
  employmentTypeSchema,
  loanSchemaSalaried,
  loanSchemaself,
} from "../../../utils/validateSchema";
import NavButton from "../../../layouts/NavButton";
import salariedImg from "../../../assets/Images/salaried.svg";
import selfEmployeedImg from "../../../assets/Images/self-employeed.svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import numberToWords from "number-to-words";
import { getPincodesAddress } from "../../../constant/services/credMudra/location";
import ManLogo from "../../../assets/Images/man.svg";
import CoupleLogo from "../../../assets/Images/couple.svg";
import ModalLoader from "../../../layouts/LoaderModal";
import ThankyouModal from "../../../layouts/ThankyouModal";
import ContinueModal from "../../../layouts/ContinueModal";

const Loan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formData = useSelector(selectGetFormData);
  const loanType = useSelector(selectLoanType);
  const PinCodeErrorData = useSelector(selectPinCodeError);
  const IndustryTypeListData = useSelector(selectIndustryTypeIdList);
  const CompanyTypeListData = useSelector(selectCompanyTypeList);
  const BankListData = useSelector(selectGetBankList);
  const CompanyAddressData = useSelector(selectCompanyAddress);
  const BusinessOwnedListData = useSelector(selectBusinessOwnedList);
  const BusinessTypeListData = useSelector(selectBusinessTypeList);
  const GenderTypesListData = useSelector(selectGenderTypesList);
  const QualificationsListData = useSelector(selectQualificationsList);
  const PersonalAddressData = useSelector(selectPersonalAddress);
  const residenceTypesListData = useSelector(selectResidenceTypesList);
  const LoanReasonListData = useSelector(selectLoanReasonList);

  const [amountInWords, setAmountInWords] = useState();
  const [incomInWords, setIncomInWords] = useState();
  const [turnoverInWords, setTurnoverInWords] = useState();
  const [monthlyProfitInWords, setMonthlyProfitInWords] = useState();
  const [Read, setRead] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [thankYouLoader, setThankYouLoader] = useState(false);
  const [ContinueLoader, setContinueLoader] = useState(false);

  const storedUserString = localStorage.getItem("dsaUser") || "";
  const parsedDsaData = storedUserString ? JSON.parse(storedUserString) : "";
  console.log(parsedDsaData, "ksdhjkdhsf");

  const navigateToEmployeType = () => {
    if (loanType !== "businessLoan") {
      navigate("/get-registered/employe-type");
    } else {
      dispatch(
        setFormData({
          employmentType: "Self Employed",
        })
      );
      navigate("/get-registered/selfemployed");
    }
  };

  useEffect(() => {
    getCompanyTypesList();
    getIndustryTypesList();
    getAllBankList();
    getBusinessOwnedList();
    getBusinessList();
    getGenderTypesList();
    getQualificationsList();
    getResidenceTypeList();
    getLoanReasonList();
    if (parsedDsaData) {
      dispatch(
        setFormData({
          ...formData,
          utm: {
            ...formData.utm,
            source: parsedDsaData?.dsaId,
          },
        })
      );
    }
  }, []);

  const getIndustryTypesList = async () => {
    if (IndustryTypeListData.length === 0) {
      const res = await getIndustryTypes();
      dispatch(setIndustryTypesListData(res));
    }
  };
  const getAllBankList = async () => {
    if (BankListData.length === 0) {
      const res = await getBanks();
      dispatch(setBankListData(res));
    }
  };
  const getCompanyTypesList = async () => {
    if (CompanyTypeListData.length === 0) {
      const res = await getCompanyTypes();
      dispatch(setCompanyTypeListData(res));
    }
  };
  const getBusinessOwnedList = async () => {
    if (CompanyTypeListData.length === 0) {
      const res = await getBusinessOwned();
      dispatch(setBusinessOwnedListData(res));
    }
  };
  const getBusinessList = async () => {
    if (CompanyTypeListData.length === 0) {
      const res = await getBusinessTypes();
      dispatch(setBusinessListData(res));
    }
  };
  const getGenderTypesList = async () => {
    if (CompanyTypeListData.length === 0) {
      const res = await getGenderTypes();
      dispatch(setGenderTypesListData(res));
    }
  };
  const getQualificationsList = async () => {
    if (CompanyTypeListData.length === 0) {
      const res = await getQualifications();
      dispatch(setQualificationsListData(res));
    }
  };
  const getResidenceTypeList = async () => {
    if (CompanyTypeListData.length === 0) {
      const res = await getResidenceTypes();
      dispatch(setResidenceTypesListData(res));
    }
  };
  const getLoanReasonList = async () => {
    if (LoanReasonListData.length === 0) {
      const res = await getLoanReasons();
      dispatch(setLoanReasonListData(res));
    }
  };

  const handleSubmit = () => {
    generateLead();
  };
  const generateLead = async () => {
    // setSubmitLoader(true);
    const eligibilityRes = await CheckEligibility(formData);
    console.log(eligibilityRes.data.status.code);
    if (eligibilityRes.data.status.code) {
      const res = await GenerateLead(formData);
      navigate("/thankyou");
    }
    // if (res?.data?.status.code === 404) {
    //   alert("Provided leadId does not exists");
    //   dispatch(
    //     setFormData({
    //       leadId: "",
    //       stage: "",
    //       contactNo: "",
    //       termsAndCondition: true,
    //       loan: {
    //         reason: null,
    //         amount: 1000,
    //         tenure: 1,
    //       },

    //       panCard: "",
    //       emailId: "",

    //       employmentType: "",

    //       employmentDetails: {
    //         companyTypeId: "",
    //         industryTypeId: "",
    //         companyName: "",
    //         designation: "",
    //         address: "",
    //         pinCode: "",
    //         countryId: "",
    //         stateId: "",
    //         cityId: "",
    //         yearsWorkingIn: "",
    //         businessTypeId: "",
    //         professionTypeId: "",
    //         turnover: "",
    //         monthlyProfit: "",
    //         income: "",
    //         salaryMode: "",
    //         bankId: "",
    //         currentAccountBankId: "",
    //         savingAccountBankId: "",
    //         businessOwnedId: "",
    //       },

    //       personalInfo: {
    //         emailId: "",
    //         firstName: "",
    //         lastName: "",
    //         genderId: "",
    //         dateOfBirth: "",
    //         qualificationId: "",
    //         maritalStatus: "",
    //       },
    //       address: {
    //         addressLine1: "",
    //         addressLine2: "",
    //         pinCode: "",
    //         countryId: "",
    //         stateId: "",
    //         cityId: "",
    //         residenceTypeId: "",
    //         yearsLivingIn: "",
    //       },
    //       finance: {
    //         panCard: "",
    //       },
    //       others: {
    //         totalEmiPaidMonthly: 0,
    //         interestedToGetCreditCard: true,
    //       },
    //       utm: {
    //         id: "",
    //         url: "",
    //         source: "",
    //         medium: "",
    //         campaign: "",
    //         term: "",
    //         content: "",
    //         clickId: "",
    //       },
    //       extras: {
    //         browser: "",
    //         operatingSystem: "",
    //         ipAddress: "",
    //         timestamp: "",
    //         userAgent: "",
    //         location: "",
    //       },
    //     })
    //   );
    //   return navigate("/");
    // }
    // if (res?.data?.status.code === 400) {
    //   alert("Provided leadId does not exists");
    //   dispatch(
    //     setFormData({
    //       leadId: "",
    //       stage: "",
    //       contactNo: "",
    //       termsAndCondition: true,
    //       loan: {
    //         reason: null,
    //         amount: 1000,
    //         tenure: 1,
    //       },

    //       panCard: "",
    //       emailId: "",

    //       employmentType: "",

    //       employmentDetails: {
    //         companyTypeId: "",
    //         industryTypeId: "",
    //         companyName: "",
    //         designation: "",
    //         address: "",
    //         pinCode: "",
    //         countryId: "",
    //         stateId: "",
    //         cityId: "",
    //         yearsWorkingIn: "",
    //         businessTypeId: "",
    //         professionTypeId: "",
    //         turnover: "",
    //         monthlyProfit: "",
    //         income: "",
    //         salaryMode: "",
    //         bankId: "",
    //         currentAccountBankId: "",
    //         savingAccountBankId: "",
    //         businessOwnedId: "",
    //       },

    //       personalInfo: {
    //         emailId: "",
    //         firstName: "",
    //         lastName: "",
    //         genderId: "",
    //         dateOfBirth: "",
    //         qualificationId: "",
    //         maritalStatus: "",
    //       },
    //       address: {
    //         addressLine1: "",
    //         addressLine2: "",
    //         pinCode: "",
    //         countryId: "",
    //         stateId: "",
    //         cityId: "",
    //         residenceTypeId: "",
    //         yearsLivingIn: "",
    //       },
    //       finance: {
    //         panCard: "",
    //       },
    //       others: {
    //         totalEmiPaidMonthly: 0,
    //         interestedToGetCreditCard: true,
    //       },
    //       utm: {
    //         id: "",
    //         url: "",
    //         source: "",
    //         medium: "",
    //         campaign: "",
    //         term: "",
    //         content: "",
    //         clickId: "",
    //       },
    //       extras: {
    //         browser: "",
    //         operatingSystem: "",
    //         ipAddress: "",
    //         timestamp: "",
    //         userAgent: "",
    //         location: "",
    //       },
    //     })
    //   );
    //   return navigate("/");
    // }
    // if (response?.duplicate === true) {
    //   setTimeout(() => {
    //     setSubmitLoader(false);
    //     dispatch(setIsLoading(false));
    //     setThankYouLoader(true);
    //   }, 2000);
    //   setTimeout(async () => {
    //     setThankYouLoader(false);
    //     navigate("/offers");
    //   }, 10000);
    // } else {
    //   const data = {
    //     leadId: sessionStorage.getItem("leadId"),
    //   };
    //   setTimeout(async () => {
    //     const res = await LeadStatus(data);
    //     const response = JSON.stringify(res);
    //     sessionStorage.setItem("Lenders", response);
    //     dispatch(setLenderDetails(res));
    //     if (res?.data?.data?.length === 0) {
    //       dispatch(setIsLoading(false));
    //       setSubmitLoader(false);
    //       navigate("/offers");
    //     } else {
    //       dispatch(setIsLoading(false));
    //       setSubmitLoader(false);
    //       // navigate("/get-registered/marketplace");
    //       setContinueLoader(true);
    //     }
    //   }, 19000);
    // }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to leave this page?";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("load", () => {
      localStorage.removeItem('dsaUser')
      window.location.href=`/?id=${btoa(parsedDsaData?.dsaId)}`
    } );
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("load", () =>  {
        localStorage.removeItem('dsaUser')
        window.location.href=`/?id=${btoa(parsedDsaData?.dsaId)}
        `});
    };
  }, []);
  const initial =
    formData.employmentType === "Salaried"
      ? {
          companyTypeId: formData.employmentDetails.companyTypeId || "",
          industryTypeId: formData.employmentDetails.industryTypeId || "",
          designation: formData.employmentDetails.designation || "",
          companyName: formData.employmentDetails.companyName || "",
          address: formData.employmentDetails.address || "",
          pinCode: formData.employmentDetails.pinCode || "",
          cityId: formData.employmentDetails.cityId || "",
          stateId: formData.employmentDetails.stateId || "",
          countryId: formData.employmentDetails.countryId || "",
          yearsWorkingIn: formData.employmentDetails.yearsWorkingIn || "",
          income: formData.employmentDetails.income || "",
          salaryMode: formData.employmentDetails.salaryMode || "",
          bankId: formData.employmentDetails.bankId || "",
        }
      : {
          businessOwnedId: formData.employmentDetails.businessOwnedId || "",
          yearsWorkingIn: formData.employmentDetails.yearsWorkingIn || "",
          businessTypeId: formData.employmentDetails.businessTypeId || "",
          industryTypeId: formData.employmentDetails.industryTypeId || "",
          companyName: formData.employmentDetails.companyName || "",
          address: formData.employmentDetails.address || "",
          pinCode: formData.employmentDetails.pinCode || "",
          cityId: formData.employmentDetails.cityId || "",
          stateId: formData.employmentDetails.stateId || "",
          countryId: formData.employmentDetails.countryId || "",
          designation: formData.employmentDetails.designation || "",
          turnover: formData.employmentDetails.turnover || "",
          monthlyProfit: formData.employmentDetails.monthlyProfit || "",
          currentAccountBankId:
            formData.employmentDetails.currentAccountBankId || "",
          savingAccountBankId:
            formData.employmentDetails.savingAccountBankId || "",
          gst: formData.employmentDetails.gst || "",
        };

  const formik = useFormik({
    initialValues: {
      ...initial,
      amount: formData.loan.amount || "",
      tenure: formData.loan.tenure || "",
      reason: formData.loan.reason || "",
      panCard: formData.panCard || "",
      emailId: formData.personalInfo.emailId || "",
      employmentType: formData.employmentType || "",
      firstName: formData.personalInfo.firstName || "",
      lastName: formData.personalInfo.lastName || "",
      dateOfBirth: formData.personalInfo.dateOfBirth || "",
      genderId: formData.personalInfo.genderId || "",
      maritalStatus: formData.personalInfo.maritalStatus || "",
      qualificationId: formData.personalInfo.qualificationId || "",
      addressLine1: formData.address.addressLine1 || "",
      addressLine2: formData.address.addressLine2 || "",
      addressPinCode: formData.address.pinCode || "",
      cityId: formData.address.cityId || "",
      stateId: formData.address.stateId || "",
      countryId: formData.address.countryId || "",
      residenceTypeId: formData.address.residenceTypeId || "",
      yearsLivingIn: formData.address.yearsLivingIn || "",
      totalEmiPaidMonthly: formData.others.totalEmiPaidMonthly || 0,
      interestedToGetCreditCard:
        formData.others.interestedToGetCreditCard || "Yes",
      termsAndCondition: formData.termsAndCondition,
    },
    validationSchema:
      formData.employmentType === "Salaried"
        ? loanSchemaSalaried
        : loanSchemaself,
    onSubmit: handleSubmit,
  });
  const maritalStatusRef = useRef();
  const salaryStatusRef = useRef();
  const employmentStatusRef = useRef();
  const ErrorFocus = () => {
    if (formik.errors?.amount) {
      document.getElementById("amount").focus();
    } else if (formik.errors?.tenure) {
      document.getElementById("tenure").focus();
    } else if (formik.errors.reason) {
      document.getElementById("reason").focus();
    } else if (formik.errors.panCard) {
      document.getElementById("panCard").focus();
    } else if (formik.errors.emailId) {
      document.getElementById("emailId").focus();
    } else if (formik.errors?.employmentType) {
      window.scrollTo(0, employmentStatusRef.current.offsetTop);
      document.getElementById("employmentType").focus();
    } else if (formik.errors.companyTypeId) {
      document.getElementById("companyTypeId").focus();
    } else if (formik.errors.address) {
      document.getElementById("address").focus();
    } else if (formik.errors.pinCode) {
      document.getElementById("pinCode").focus();
    } else if (formik.errors.income) {
      document.getElementById("income").focus();
    } else if (formik.errors.salaryMode) {
      window.scrollTo(0, salaryStatusRef.current.offsetTop);
      document.getElementById("salaryMode").focus();
    } else if (formik.errors.bankId) {
      document.getElementById("bankId").focus();
    } else if (formik.errors.businessOwnedId) {
      document.getElementById("businessOwnedId").focus();
    } else if (formik.errors.yearsWorkingIn) {
      document.getElementById("yearsWorkingIn").focus();
    } else if (formik.errors.businessTypeId) {
      document.getElementById("businessTypeId").focus();
    } else if (formik.errors.industryTypeId) {
      document.getElementById("industryTypeId").focus();
    } else if (formik.errors.companyName) {
      document.getElementById("companyName").focus();
    } else if (formik.errors.designation) {
      document.getElementById("designation").focus();
    } else if (formik.errors.turnover) {
      document.getElementById("turnover").focus();
    } else if (formik.errors.monthlyProfit) {
      document.getElementById("monthlyProfit").focus();
    } else if (formik.errors.gst) {
      document.getElementById("gst").focus();
    } else if (formik.errors.currentAccountBankId) {
      document.getElementById("currentAccountBankId").focus();
    } else if (formik.errors.savingAccountBankId) {
      document.getElementById("savingAccountBankId").focus();
    } else if (formik.errors.firstName) {
      document.getElementById("firstName").focus();
    } else if (formik.errors.lastName) {
      document.getElementById("lastName").focus();
    } else if (formik.errors.dateOfBirth) {
      document.getElementById("dateOfBirth").focus();
    } else if (formik.errors.genderId) {
      document.getElementById("genderId").focus();
    } else if (formik.errors.maritalStatus) {
      window.scrollTo(0, maritalStatusRef.current.offsetTop);
      document.getElementById("maritalStatus").focus();
    } else if (formik.errors.qualificationId) {
      document.getElementById("qualificationId").focus();
    } else if (formik.errors.addressLine1) {
      document.getElementById("addressLine1").focus();
    } else if (formik.errors.addressLine2) {
      document.getElementById("addressLine2").focus();
    } else if (formik.errors.addressPinCode) {
      document.getElementById("pinCodeAddress").focus();
    } else if (formik.errors.residenceTypeId) {
      document.getElementById("residenceTypeId").focus();
    } else if (formik.errors.yearsLivingIn) {
      document.getElementById("yearsLivingIn").focus();
    } else if (formik.errors.totalEmiPaidMonthly) {
      document.getElementById("totalEmiPaidMonthly").focus();
    } else if (formik.errors.interestedToGetCreditCard) {
      document.getElementById("interestedToGetCreditCard").focus();
    } else if (formik.errors.termsAndCondition) {
      document.getElementById("termsAndCondition").focus();
    }
    return false;
  };

  const getCompanyAddress = async (event) => {
    if (/^\d*$/.test(event.target.value) && event.target.value.length <= 6 &&
    event.target.value !== "000000")  {
      dispatch(
        setPinCodeError({
          ...PinCodeErrorData,
          companyPincode: "",
        })
      );
      dispatch(
        setFormData({
          employmentDetails: {
            ...formData.employmentDetails,
            pinCode: event.target.value,
          },
        })
      );
      if (event.target.value?.length === 6) {
        dispatch(
          setPinCodeError({
            ...PinCodeErrorData,
            companyPincode: "",
          })
        );
        const data = {
          pinCode: event.target.value,
        };
        const res = await getPincodesAddress(data);
        if (res?.data?.status.code === 404) {
          dispatch(
            setPinCodeError({
              ...PinCodeErrorData,
              companyPincode: "Pin code does not exists",
            })
          );
        } else {
          dispatch(
            setPinCodeError({
              ...PinCodeErrorData,
              companyPincode: "",
            })
          );
        }
        dispatch(
          setFormData({
            employmentDetails: {
              ...formData.employmentDetails,
              pinCode: event.target.value,
              cityId: res?.data?.data?.city?.id,
              stateId: res?.data?.data?.state?.id,
              countryId: res?.data?.data?.country?.id,
            },
          })
        );
        dispatch(
          setCompanyAddressData({
            city: res?.data?.data?.city?.name,
            state: res?.data?.data?.state?.name,
            country: res?.data?.data?.country?.name,
          })
        );
      } else {
        dispatch(
          setFormData({
            employmentDetails: {
              ...formData.employmentDetails,
              pinCode: event.target.value,
              cityId: "",
              stateId: "",
              countryId: "",
            },
          })
        );
        dispatch(
          setCompanyAddressData({
            city: "",
            state: "",
            country: "",
          })
        );
      }
    }
  };

  const getAddress = async (event) => {
    if (
      /^\d*$/.test(event.target.value) &&
      event.target.value.length <= 6 &&
      event.target.value !== "000000"
    ) {
      dispatch(
        setPinCodeError({
          ...PinCodeErrorData,
          addressPincode: "",
        })
      );
      dispatch(
        setFormData({
          address: {
            ...formData.address,
            pinCode: event.target.value,
          },
        })
      );
      if (event.target.value?.length === 6 ) {
        dispatch(
          setPinCodeError({
            ...PinCodeErrorData,
            addressPincode: "",
          })
        );
        const data = {
          pinCode: event.target.value,
        };
        const res = await getPincodesAddress(data);
        if (res?.data?.status.code === 404) {
          dispatch(
            setPinCodeError({
              ...PinCodeErrorData,
              addressPincode: "Pin code does not exists",
            })
          );
        } else {
          dispatch(
            setPinCodeError({
              ...PinCodeErrorData,
              addressPincode: "",
            })
          );
        }
        dispatch(
          setFormData({
            address: {
              ...formData.address,
              pinCode: event.target.value,
              cityId: res?.data?.data?.city?.id,
              stateId: res?.data?.data?.state?.id,
              countryId: res?.data?.data?.country?.id,
            },
          })
        );
        dispatch(
          setPersonalAddressData({
            city: res?.data?.data?.city?.name,
            state: res?.data?.data?.state?.name,
            country: res?.data?.data?.country?.name,
          })
        );
      } else {
        dispatch(
          setFormData({
            address: {
              ...formData.address,
              pinCode: event.target.value,
              cityId: "",
              stateId: "",
              countryId: "",
            },
          })
        );
        dispatch(
          setPersonalAddressData({
            city: "",
            state: "",
            country: "",
          })
        );
      }
    }
  };

  const Year = [
    { label: "1 Year", value: "1 Year" },
    { label: "1-2 Years", value: "1-2 Years" },
    { label: "3 Years", value: "3 Years" },
    { label: "3-5 Years", value: "3-5 Years" },
    { label: "5+ Years", value: "5+ Years" },
  ];

  const options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const offRead = () => {
    setRead(false);
  };

  const toggleRead = () => {
    setRead(true);
  };
  return (
    <>
      <CssBaseline />
      <form onSubmit={formik.handleSubmit}>
        <Grid container rowGap={6}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "28px", md: "35px", lg: "36px", xl: "40px" },
              fontWeight: "bold",
            }}
          >
            Loan Details
          </Typography>
          <Grid container xs={12} rowGap={2}>
            <Grid container xs={12} rowGap={1}>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" sx={{ fontSize: { xs: "16px" } }}>
                  Enter the Loan Amount:
                </Typography>
                <Box
                  sx={{
                    width: "90px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "2px solid #c6e5cb",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#5fb76d",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    ₹
                  </Typography>
                  <InputBase
                    id="amount"
                    name="amount"
                    sx={{ width: "80px", ml: 1, padding: 0 }}
                    value={formData?.loan?.amount}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        if (e.target.value >= 0 && e.target.value <= 1500000) {
                          dispatch(
                            setFormData({
                              loan: {
                                ...formData.loan,
                                amount: e.target.value,
                              },
                            })
                          );
                          const number = parseFloat(e.target.value);
                          if (!isNaN(number) && isFinite(number)) {
                            setAmountInWords(numberToWords.toWords(number));
                          } else {
                            setAmountInWords("");
                          }

                          formik.handleChange(e);
                        }
                      }
                    }}
                  />
                </Box>
              </Grid>
              <input
                style={{ width: "100%" }}
                id="amount"
                type="range"
                name="amount"
                className="form-control-range slider"
                value={formData?.loan?.amount}
                onBlur={formik.handleBlur}
                onChange={(e, newValue) => {
                  if (/^\d*$/.test(e.target.value)) {
                    dispatch(
                      setFormData({
                        loan: {
                          ...formData.loan,
                          amount: e.target.value,
                        },
                      })
                    );
                    const number = parseFloat(e.target.value);
                    if (!isNaN(number) && isFinite(number)) {
                      setAmountInWords(numberToWords.toWords(number));
                    } else {
                      setAmountInWords("");
                    }
                    formik.handleChange(e);
                  }
                }}
                min="1000"
                max="1500000"
                step="1000"
              />
              <Grid
                container
                xs={12}
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <Typography sx={{ fontSize: "13px", color: "" }}>
                  1000
                </Typography>
                <Typography sx={{ fontSize: "13px", color: "" }}>
                  1500000
                </Typography>
              </Grid>
              {formik.touched.amount && formik.errors.amount && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.amount}
                </FormHelperText>
              )}
            </Grid>
            {amountInWords ? (
              <Typography variant="subtitle1">
                {amountInWords?.charAt(0).toUpperCase() +
                  amountInWords?.slice(1)}{" "}
                Only
              </Typography>
            ) : (
              ""
            )}
            <Grid container xs={12} rowGap={1}>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" sx={{ fontSize: { xs: "16px" } }}>
                  Enter the Loan Tenure:
                </Typography>
                <Box
                  sx={{
                    width: "90px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "2px solid #c6e5cb",
                  }}
                >
                  <InputBase
                    id="tenure"
                    name="tenure"
                    sx={{ ml: 1, padding: 0 }}
                    value={formData?.loan?.tenure}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      if (
                        !isNaN(e.target.value) &&
                        !/\s/.test(e.target.value)
                      ) {
                        if (e.target.value >= 0 && e.target.value <= 60) {
                          dispatch(
                            setFormData({
                              loan: {
                                ...formData.loan,
                                tenure: e.target.value,
                              },
                            })
                          );
                          formik.handleChange(e);
                        }
                      }
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Inter, sans-serif",
                    }}
                  >
                    Month
                  </Typography>
                </Box>
              </Grid>
              <input
                style={{ width: "100%" }}
                id="tenure"
                name="tenure"
                type="range"
                className="form-control-range slider"
                value={formData?.loan?.tenure}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      loan: {
                        ...formData.loan,
                        tenure: event.target.value,
                      },
                    })
                  );
                  formik.handleChange(event);
                }}
                min="1"
                max="60"
                step="1"
              />
              <Grid
                container
                xs={12}
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <Typography sx={{ fontSize: "13px", color: "" }}>
                  1 Month
                </Typography>
                <Typography sx={{ fontSize: "13px", color: "" }}>
                  60 Months
                </Typography>
              </Grid>
              {formik.touched.tenure && formik.errors.tenure && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.tenure}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Reason For Loan*</Typography>
              <Autocomplete
                id="reason"
                name="reason"
                fullWidth
                disablePortal
                options={LoanReasonListData ? LoanReasonListData : ""}
                value={
                  LoanReasonListData?.find(
                    (option) => formData?.loan?.reason === option.label
                  )?.label
                }
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      loan: {
                        ...formData.loan,
                        reason: newValue?.label,
                      },
                    })
                  );

                  formik.setFieldValue("reason", newValue?.label);
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Loan Reason" />
                )}
              />
              {formik.touched?.reason && formik.errors?.reason && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.reason}
                </FormHelperText>
              )}
            </Grid>
            {formData?.loan?.reason === "Others" && (
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Other Reason For Loan*
                </Typography>
                <TextField
                  id="otherReason"
                  name="otherReason"
                  variant="outlined"
                  fullWidth
                  placeholder="Loan Reason"
                  value={formData?.loan?.otherReason}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (/^(?![ ]).*$/.test(e.target.value)) {
                      dispatch(
                        setFormData({
                          loan: {
                            ...formData.loan,
                            otherReason: e.target.value,
                          },
                        })
                      );
                    }
                    formik.handleChange(e);
                  }}
                />

                {formik?.touched?.otherReason &&
                  formik?.errors?.otherReason && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik?.errors?.otherReason}
                    </FormHelperText>
                  )}
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1">PAN Card Number *</Typography>
              <TextField
                id="panCard"
                name="panCard"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 10 }}
                placeholder="PAN NUMBER"
                value={formData?.panCard}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  const inputValue = e.target.value.toUpperCase();
                  if (e.target.value.length <= 10) {
                    dispatch(
                      setFormData({
                        ...formData,
                        panCard: inputValue,
                        finance: {
                          panCard: inputValue,
                        },
                      })
                    );
                  }
                  formik.handleChange(e);
                }}
              />

              {formik.touched.panCard && formik.errors?.panCard && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.panCard}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Email Address *</Typography>
              <TextField
                id="emailId"
                variant="outlined"
                name="emailId"
                fullWidth
                placeholder="abc@abc.com"
                value={formData?.personalInfo?.emailId}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  if (/^(?![ ]).*$/.test(event.target.value)) {
                    dispatch(
                      setFormData({
                        personalInfo: {
                          ...formData.personalInfo,
                          emailId: event.target.value,
                        },
                      })
                    );
                    formik.handleChange(event);
                  }
                }}
              />

              {formik.touched.emailId && formik.errors?.emailId && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors?.emailId}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          {/* <NavButton ErrorFocus={ErrorFocus} /> */}
        </Grid>
        <br />
        <br />
        {/* employmentType */}
        <Grid ref={employmentStatusRef} container rowGap={5}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "28px", md: "35px", lg: "36px", xl: "40px" },
              fontWeight: "800",
            }}
          >
            Employment Type
          </Typography>
          <Grid
            container
            rowGap={4}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Typography variant="h4" sx={{ fontSize: "16px" }}>
              Your Profession
            </Typography>
            <RadioGroup
              row
              id="employmentType"
              value={formik.values.employmentType}
              onChange={(e) => {
                console.log(e.target.value);
                dispatch(
                  setFormData({
                    employmentType: e.target.value,
                  })
                );
                formik.handleChange("employmentType")(e.target.value);
              }}
              sx={{ display: "flex", gap: 4, justifyContent: "center" }}
            >
              <FormControlLabel
                value="Salaried"
                name="employmentType"
                control={<Radio value="Salaried" sx={{ display: "none" }} />}
                label={
                  <Box
                    sx={{
                      width: "120px",
                      height: "116px",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                      color: "#243771",
                      border: "1px solid #000",
                      borderRadius: "20px",
                      backgroundColor:
                        formData?.employmentType === "Salaried"
                          ? { backgroundColor: "#F7D64A", border: "none" }
                          : { backgroundColor: "transparent" },
                    }}
                  >
                    <img src={salariedImg} alt="type" />
                    <Typography sx={{ fontSize: "11px" }}>Salaried</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="Self Employed"
                name="employmentType"
                control={
                  <Radio value="Self Employed" sx={{ display: "none" }} />
                }
                label={
                  <Box
                    sx={{
                      width: "120px",
                      height: "116px",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                      color: "#243771",
                      border: "1px solid #000",
                      borderRadius: "20px",
                      backgroundColor:
                        formData?.employmentType === "Self Employed"
                          ? { backgroundColor: "#F7D64A", border: "none" }
                          : { backgroundColor: "transparent" },
                    }}
                  >
                    <img src={selfEmployeedImg} alt="type" />
                    <Typography sx={{ fontSize: "11px" }}>
                      Self Employed
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </Grid>
          {formik.touched.employmentType && formik.errors?.employmentType && (
            <FormHelperText
              sx={{
                margin: 0,
                color: "red",
                fontSize: "14px",
                ml: 1,
              }}
            >
              {formik.errors.employmentType}
            </FormHelperText>
          )}
          {/* <NavButton handlePrev={handlePrev} ErrorFocus={ErrorFocus} /> */}
        </Grid>
        <br />
        <br />

        {formData.employmentType === "Self Employed" ? (
          <Grid container rowGap={6}>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "28px", md: "35px", lg: "36px", xl: "40px" },
                fontWeight: "900",
              }}
            >
              Employment Details
            </Typography>

            <Grid container xs={12} rowGap={2}>
              <Typography varient="h6" sx={{ color: "green" }}>
                Business
              </Typography>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Business Run By* </Typography>
                <Autocomplete
                  id="businessOwnedId"
                  name="businessOwnedId"
                  disablePortal
                  options={BusinessOwnedListData}
                  value={
                    BusinessOwnedListData?.find(
                      (option) =>
                        formData?.employmentDetails?.businessOwnedId ===
                        option.value
                    )?.label
                  }
                  onBlur={formik.handleBlur}
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          businessOwnedId: newValue?.value,
                        },
                      })
                    );
                    formik.setFieldValue("businessOwnedId", newValue);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Please Select" />
                  )}
                />
                {formik.touched?.businessOwnedId &&
                  formik.errors?.businessOwnedId && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.businessOwnedId}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  How many years have you been working at your current
                  Business?*
                </Typography>
                <Autocomplete
                  disablePortal
                  id="yearsWorkingIn"
                  name="yearsWorkingIn"
                  options={Year}
                  fullWidth
                  onBlur={formik.handleBlur}
                  value={formData?.employmentDetails?.yearsWorkingIn}
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          yearsWorkingIn: newValue?.value,
                        },
                      })
                    );
                    formik.setFieldValue("yearsWorkingIn", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Please Select" />
                  )}
                />
                {formik.touched?.yearsWorkingIn &&
                  formik.errors?.yearsWorkingIn && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.yearsWorkingIn}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Business Type*</Typography>
                <Autocomplete
                  disablePortal
                  onBlur={formik.handleBlur}
                  id="businessTypeId"
                  name="businessTypeId"
                  options={BusinessTypeListData ? BusinessTypeListData : ""}
                  fullWidth
                  value={
                    BusinessTypeListData?.find(
                      (option) =>
                        formData?.employmentDetails?.businessTypeId ===
                        option.value
                    )?.label
                  }
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          businessTypeId: newValue?.value,
                        },
                      })
                    );
                    formik.setFieldValue("businessTypeId", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField placeholder="Please Select" {...params} />
                  )}
                />
                {formik.touched?.businessTypeId &&
                  formik.errors?.businessTypeId && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.businessTypeId}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Industry</Typography>
                <Autocomplete
                  disablePortal
                  onBlur={formik.handleBlur}
                  id="industryTypeId"
                  name="industryTypeId"
                  options={IndustryTypeListData ? IndustryTypeListData : ""}
                  fullWidth
                  value={
                    IndustryTypeListData?.find(
                      (option) =>
                        formData?.employmentDetails?.industryTypeId ===
                        option.value
                    )?.label
                  }
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          industryTypeId: newValue?.value,
                        },
                      })
                    );
                    formik.setFieldValue("industryTypeId", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Please Select..." />
                  )}
                />
                {formik.touched?.industryTypeId &&
                  formik.errors?.industryTypeId && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.industryTypeId}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Company Name</Typography>
                <TextField
                  variant="outlined"
                  id="companyName"
                  name="companyName"
                  fullWidth
                  onBlur={formik.handleBlur}
                  placeholder="Enter Your Company Name"
                  value={formData?.employmentDetails?.companyName}
                  onChange={(event, newValue) => {
                    if (/^(?![ ]).*$/.test(event.target.value)) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            companyName: event.target.value,
                          },
                        })
                      );
                      formik.handleChange(event);
                    }
                  }}
                />

                {formik.touched?.companyName && formik.errors?.companyName && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.companyName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Company Address</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="address"
                  onBlur={formik.handleBlur}
                  name="address"
                  placeholder="Enter the Company address"
                  value={formData?.employmentDetails?.address}
                  onChange={(event, newValue) => {
                    if (
                      /^(?![^A-Za-z0-9])[A-Za-z0-9\s,-/]*$/.test(
                        event.target.value
                      )
                    ) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            address: event.target.value,
                          },
                        })
                      );
                      formik.handleChange(event);
                    }
                  }}
                />
                {formik.touched?.address && formik.errors?.address && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.address}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  fullWidth
                  id="pinCode"
                  name="pinCode"
                  placeholder="Enter the Company Pincode"
                  value={formData?.employmentDetails?.pinCode}
                  onChange={(event) => {
                    getCompanyAddress(event);
                    formik.handleChange(event);
                  }}
                />
                {formik.touched?.pinCode && formik.errors?.pinCode && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.pinCode}
                  </FormHelperText>
                )}
                {PinCodeErrorData?.companyPincode ? (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {PinCodeErrorData?.companyPincode}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="cityId"
                  name="cityId"
                  disabled
                  placeholder="City"
                  value={CompanyAddressData?.city}
                />

                {/* {formik.errors?.city && (
            <FormHelperText
              sx={{
                margin: 0,
                color: "red",
                fontSize: "14px",
                ml: 1,
              }}
            >
              {formik.errors.city}
            </FormHelperText>
          )} */}
              </Grid>
              <Grid xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="stateId"
                  name="stateId"
                  disabled
                  placeholder="State"
                  value={CompanyAddressData?.state}
                />
                {/* {formik.errors?.stateId && (
            <FormHelperText
              sx={{
                margin: 0,
                color: "red",
                fontSize: "14px",
                ml: 1,
              }}
            >
              {formik.errors.stateId}
            </FormHelperText>
          )} */}
              </Grid>
              <Grid xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  id="countryId"
                  name="countryId"
                  placeholder="Country"
                  value={CompanyAddressData?.country}
                />
                {/* {formik.errors?.country && (
            <FormHelperText
              sx={{
                margin: 0,
                color: "red",
                fontSize: "14px",
                ml: 1,
              }}
            >
              {formik.errors.country}
            </FormHelperText>
          )} */}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Designation</Typography>
                <TextField
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  fullWidth
                  id="designation"
                  name="designation"
                  placeholder="Enter Your Designation"
                  value={formData?.employmentDetails?.designation}
                  onChange={(event, newValue) => {
                    if (/^(?![ ]).*$/.test(event.target.value)) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            designation: event.target.value,
                          },
                        })
                      );
                      formik.handleChange(event);
                    }
                  }}
                />
                {formik.touched?.designation && formik.errors?.designation && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.designation}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Gross Annual Sales/Turnover *
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  id="turnover"
                  name="turnover"
                  placeholder="Enter Annual Turnover"
                  value={formData?.employmentDetails?.turnover}
                  onChange={(e, newValue) => {
                    if (
                      /^\d*$/.test(e.target.value) &&
                      e.target.value.length <= 10
                    ) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            turnover: e.target.value,
                          },
                        })
                      );
                      const number = parseFloat(e.target.value);
                      if (!isNaN(number) && isFinite(number)) {
                        setTurnoverInWords(numberToWords.toWords(number));
                      } else {
                        setTurnoverInWords("");
                      }
                      formik.handleChange(e);
                    }
                  }}
                />
                {formik.touched?.turnover && formik.errors?.turnover && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.turnover}
                  </FormHelperText>
                )}
                {turnoverInWords ? (
                  <Typography variant="subtitle1">
                    {turnoverInWords?.charAt(0).toUpperCase() +
                      turnoverInWords?.slice(1)}{" "}
                    Only
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Monthly Profit</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  onBlur={formik.handleBlur}
                  id="monthlyProfit"
                  name="monthlyProfit"
                  placeholder="Enter Your Monthly Profit"
                  value={formData?.employmentDetails?.monthlyProfit}
                  onChange={(e, newValue) => {
                    if (
                      /^\d*$/.test(e.target.value) &&
                      e.target.value.length <= 7
                    ) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            monthlyProfit: e.target.value,
                          },
                        })
                      );
                      const number = parseFloat(e.target.value);
                      if (!isNaN(number) && isFinite(number)) {
                        setMonthlyProfitInWords(numberToWords.toWords(number));
                      } else {
                        setMonthlyProfitInWords("");
                      }
                      formik.handleChange(e);
                    }
                  }}
                />
                {formik.touched?.monthlyProfit &&
                  formik.errors?.monthlyProfit && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.monthlyProfit}
                    </FormHelperText>
                  )}
                {monthlyProfitInWords ? (
                  <Typography variant="subtitle1">
                    {monthlyProfitInWords?.charAt(0).toUpperCase() +
                      monthlyProfitInWords?.slice(1)}{" "}
                    Only
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  GST{" "}
                  {formData?.employmentDetails?.turnover >= 2000000 && (
                    <span>*</span>
                  )}
                </Typography>
                <TextField
                  variant="outlined"
                  id="gst"
                  name="gst"
                  fullWidth
                  onBlur={formik.handleBlur}
                  inputProps={{ maxLength: 15 }}
                  placeholder="Enter GST Number"
                  value={formData?.employmentDetails?.gst}
                  onChange={(event, newValue) => {
                    const inputValue = event.target.value.toUpperCase();
                    if (event.target.value.length <= 15) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            gst: inputValue,
                          },
                        })
                      );
                      formik.handleChange(event);
                    }
                  }}
                />

                {formik.touched?.gst && formik.errors?.gst && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.gst}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Business/Current Account Is With? *
                </Typography>
                <Autocomplete
                  disablePortal
                  id="currentAccountBankId"
                  name="currentAccountBankId"
                  onBlur={formik.handleBlur}
                  options={BankListData ? BankListData : ""}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Please Select" />
                  )}
                  value={
                    BankListData?.find(
                      (option) =>
                        formData?.employmentDetails?.currentAccountBankId ===
                        option.value
                    )?.label
                  }
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          currentAccountBankId: newValue?.value,
                        },
                      })
                    );
                    formik.setFieldValue("currentAccountBankId", newValue);
                  }}
                />
                {formik.touched?.currentAccountBankId &&
                  formik.errors?.currentAccountBankId && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.currentAccountBankId}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Primary/Savings Bank Account Is With? *
                </Typography>
                <Autocomplete
                  disablePortal
                  id="savingAccountBankId"
                  name="savingAccountBankId"
                  onBlur={formik.handleBlur}
                  options={BankListData ? BankListData : ""}
                  fullWidth
                  value={
                    BankListData?.find(
                      (option) =>
                        formData?.employmentDetails?.savingAccountBankId ===
                        option.value
                    )?.label
                  }
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          savingAccountBankId: newValue?.value,
                        },
                      })
                    );
                    formik.setFieldValue("savingAccountBankId", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Please Select" />
                  )}
                />
                {formik.touched?.savingAccountBankId &&
                  formik.errors?.savingAccountBankId && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.savingAccountBankId}
                    </FormHelperText>
                  )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container rowGap={6}>
            <Typography
              variant="h4"
              sx={{
                fontSize: { xs: "28px", md: "35px", lg: "36px", xl: "40px" },
                fontWeight: "900",
              }}
            >
              Employment Details
            </Typography>
            <Grid container xs={12} rowGap={2}>
              <Typography varient="h6" sx={{ color: "green" }}>
                Salaried Employee
              </Typography>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Company Type *</Typography>
                <Autocomplete
                  id="companyTypeId"
                  name="companyTypeId"
                  disablePortal
                  options={CompanyTypeListData}
                  value={
                    CompanyTypeListData?.find(
                      (option) =>
                        formData?.employmentDetails?.companyTypeId ===
                        option.value
                    )?.label
                  }
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          companyTypeId: newValue?.value,
                        },
                      })
                    );

                    formik.setFieldValue("companyTypeId", newValue);
                  }}
                  onBlur={formik.handleBlur}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Company Type" />
                  )}
                />
                {formik.touched?.companyTypeId &&
                  formik.errors?.companyTypeId && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.companyTypeId}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Industry *</Typography>
                <Autocomplete
                  id="industryTypeId"
                  name="industryTypeId"
                  disablePortal
                  options={IndustryTypeListData ? IndustryTypeListData : ""}
                  fullWidth
                  value={
                    IndustryTypeListData?.find(
                      (option) =>
                        formData?.employmentDetails?.industryTypeId ===
                        option.value
                    )?.label
                  }
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          industryTypeId: newValue?.value,
                        },
                      })
                    );
                    formik.setFieldValue("industryTypeId", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Industry Type" />
                  )}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.industryTypeId &&
                  formik.errors?.industryTypeId && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.industryTypeId}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Designation *</Typography>
                <TextField
                  id="designation"
                  name="designation"
                  variant="outlined"
                  fullWidth
                  type="text"
                  placeholder="Enter the Designation"
                  value={formData?.employmentDetails?.designation}
                  onChange={(event, newValue) => {
                    if (/^(?![ ]).*$/.test(event.target.value)) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            designation: event.target.value,
                          },
                        })
                      );
                      formik.handleChange(event);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.designation && formik.errors?.designation && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.designation}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Company Name *</Typography>
                <TextField
                  id="companyName"
                  name="companyName"
                  variant="outlined"
                  fullWidth
                  type="text"
                  placeholder="Enter the Company Name"
                  value={formData?.employmentDetails?.companyName}
                  onChange={(event, newValue) => {
                    if (/^(?![ ]).*$/.test(event.target.value)) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            companyName: event.target.value,
                          },
                        })
                      );
                      formik.handleChange(event);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.companyName && formik.errors?.companyName && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.companyName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Company Address *</Typography>
                <TextField
                  id="address"
                  name="address"
                  variant="outlined"
                  fullWidth
                  type="text"
                  placeholder="Enter the Company address"
                  value={formData?.employmentDetails.address}
                  onChange={(event, newValue) => {
                    if (
                      /^(?![^A-Za-z0-9])[A-Za-z0-9\s,-/]*$/.test(
                        event.target.value
                      )
                    ) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            address: event.target.value,
                          },
                        })
                      );
                      formik.handleChange(event);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.address && formik.errors?.address && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.address}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Company Pincode *</Typography>
                <TextField
                  id="pinCode"
                  variant="outlined"
                  name="pinCode"
                  fullWidth
                  placeholder="Enter the Company Pincode"
                  value={formData?.employmentDetails?.pinCode}
                  onChange={(event) => {
                    getCompanyAddress(event);
                    formik.handleChange(event);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched?.pinCode && formik.errors.pinCode && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.pinCode}
                  </FormHelperText>
                )}
                {PinCodeErrorData?.companyPincode ? (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {PinCodeErrorData?.companyPincode}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">City *</Typography>
                <TextField
                  onBlur={formik.handleBlur}
                  id="city"
                  name="cityId"
                  variant="outlined"
                  fullWidth
                  disabled
                  placeholder="City"
                  value={CompanyAddressData?.city}
                  onChange={(e) => formik.handleChange(e)}
                />
                {/* {formik.touched?.cityId  && formik.errors?.cityId && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.cityId}
                </FormHelperText>
              )} */}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">State *</Typography>
                <TextField
                  variant="outlined"
                  id="state"
                  name="state"
                  fullWidth
                  disabled
                  placeholder="State"
                  value={CompanyAddressData?.state}
                />
                {/* {formik.touched?.stateId  && formik.errors?.stateId && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.stateId}
                </FormHelperText>
              )} */}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Country *</Typography>

                <TextField
                  id="country"
                  variant="outlined"
                  name="country"
                  fullWidth
                  disabled
                  placeholder="Country"
                  value={CompanyAddressData?.country}
                />
                {/* {formik.touched?.countryId  && formik.errors?.countryId && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.countryId}
                </FormHelperText>
              )} */}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  How many years have you been working at your current company?*
                </Typography>
                <Autocomplete
                  disablePortal
                  id="yearsWorkingIn"
                  name="yearsWorkingIn"
                  options={Year}
                  fullWidth
                  onBlur={formik.handleBlur}
                  value={formData?.employmentDetails?.yearsWorkingIn}
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          yearsWorkingIn: newValue?.value,
                        },
                      })
                    );
                    formik.setFieldValue("yearsWorkingIn", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField placeholder="Please Select" {...params} />
                  )}
                />
                {formik.touched?.yearsWorkingIn &&
                  formik.errors?.yearsWorkingIn && (
                    <FormHelperText
                      sx={{
                        margin: 0,
                        color: "red",
                        fontSize: "14px",
                        ml: 1,
                      }}
                    >
                      {formik.errors.yearsWorkingIn}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Monthly Income *</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="income"
                  name="income"
                  placeholder="Enter the Monthly Income"
                  value={formData?.employmentDetails?.income}
                  onChange={(e, newValue) => {
                    if (/^\d*$/.test(e.target.value)) {
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            income: e.target.value,
                          },
                        })
                      );
                      const number = parseFloat(e.target.value);
                      if (!isNaN(number) && isFinite(number)) {
                        setIncomInWords(numberToWords.toWords(number));
                      } else {
                        setIncomInWords("");
                      }
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched?.income && formik.errors?.income && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.income}
                  </FormHelperText>
                )}
                {incomInWords ? (
                  <Typography variant="subtitle1">
                    {incomInWords?.charAt(0).toUpperCase() +
                      incomInWords?.slice(1)}{" "}
                    Only
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item ref={salaryStatusRef} xs={12}>
                <Grid
                  container
                  rowGap={4}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography variant="subtitle1">Mode of Salary *</Typography>
                  <RadioGroup
                    row
                    id="salaryMode"
                    value={formik.values.employmentType}
                    onChange={(e) => {
                      console.log(e.target.value);
                      dispatch(
                        setFormData({
                          employmentDetails: {
                            ...formData.employmentDetails,
                            salaryMode: e.target.value,
                          },
                        })
                      );
                      formik.handleChange("salaryMode")(e.target.value);
                    }}
                    sx={{ display: "flex", gap: 4, justifyContent: "center" }}
                  >
                    <FormControlLabel
                      value="Bank Transfer"
                      name="salaryMode"
                      control={<Radio sx={{ display: "none" }} />}
                      label={
                        <Box
                          sx={{
                            width: " 120px",
                            height: "116px",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "15px",
                            color: "#243771",
                            border: "1px solid #000",
                            borderRadius: "20px",
                            backgroundColor:
                              formData?.employmentDetails?.salaryMode ===
                              "Bank Transfer"
                                ? {
                                    backgroundColor: " #F7D64A",
                                    border: "none",
                                  }
                                : { backgroundColor: "transparent" },
                          }}
                        >
                          <img src={bankTransfer} alt="type" />
                          <Typography sx={{ fontSize: "11px" }}>
                            Bank Transfer
                          </Typography>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value="Cash"
                      name="salaryMode"
                      control={<Radio sx={{ display: "none" }} />}
                      label={
                        <Box
                          sx={{
                            width: " 120px",
                            height: "116px",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "15px",
                            color: "#243771",
                            border: "1px solid #000",
                            borderRadius: "20px",
                            backgroundColor:
                              formData?.employmentDetails?.salaryMode === "Cash"
                                ? {
                                    backgroundColor: " #F7D64A",
                                    border: "none",
                                  }
                                : { backgroundColor: "transparent" },
                          }}
                        >
                          <img src={cash} alt="type" />
                          <Typography sx={{ fontSize: "11px" }}>
                            Cash
                          </Typography>
                        </Box>
                      }
                    />
                  </RadioGroup>
                </Grid>
                {formik.touched?.salaryMode && formik.errors?.salaryMode && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors?.salaryMode}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Name of Bank Account *
                </Typography>
                <Autocomplete
                  disablePortal
                  id="bankId"
                  name="bankId"
                  options={BankListData ? BankListData : ""}
                  value={
                    BankListData?.find(
                      (option) =>
                        formData?.employmentDetails?.bankId === option.value
                    )?.label
                  }
                  onChange={(event, newValue) => {
                    dispatch(
                      setFormData({
                        employmentDetails: {
                          ...formData.employmentDetails,
                          bankId: newValue?.value,
                        },
                      })
                    );
                    formik.setFieldValue("bankId", newValue);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField placeholder="Select Bank Name" {...params} />
                  )}
                  onBlur={formik.handleBlur}
                />
                {formik.touched?.bankId && formik.errors?.bankId && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.bankId}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <>
              {/* <NavButton handlePrev={handlePrev} ErrorFocus={ErrorFocus} /> */}
            </>
          </Grid>
        )}
        <br />
        <br />

        {/* PersonalDetails */}
        <Grid container rowGap={6}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "28px", md: "35px", lg: "36px", xl: "40px" },
              fontWeight: "900",
            }}
          >
            Personal Details
          </Typography>

          <Grid container xs={12} rowGap={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                First Name (As per PAN card) *
              </Typography>
              <TextField
                variant="outlined"
                id="firstName"
                name="firstName"
                fullWidth
                placeholder="Enter Your First Name "
                value={formData?.personalInfo?.firstName}
                onChange={(event, newValue) => {
                  if (/^[A-Za-z]*$/.test(event.target.value)) {
                    dispatch(
                      setFormData({
                        personalInfo: {
                          ...formData.personalInfo,
                          firstName: event.target.value,
                        },
                      })
                    );
                    formik.handleChange(event);
                  }
                }}
                onBlur={formik.handleBlur}
              />

              {formik.touched?.firstName && formik.errors?.firstName && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik?.errors?.firstName}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Last Name (As per PAN card) *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                id="lastName"
                name="lastName"
                placeholder="Enter the Last Name "
                value={formData?.personalInfo?.lastName}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  if (/^[A-Za-z]*$/.test(event.target.value)) {
                    dispatch(
                      setFormData({
                        personalInfo: {
                          ...formData.personalInfo,
                          lastName: event.target.value,
                        },
                      })
                    );
                    formik.handleChange(event);
                  }
                }}
              />

              {formik.touched?.lastName && formik.errors?.lastName && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik?.errors?.lastName}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">DOB</Typography>
              <TextField
                type="date"
                variant="outlined"
                fullWidth
                id="dateOfBirth"
                name="dateOfBirth"
                value={formData?.personalInfo?.dateOfBirth}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      personalInfo: {
                        ...formData.personalInfo,
                        dateOfBirth: event.target.value,
                      },
                    })
                  );
                  formik.handleChange(event);
                }}
              />

              {formik.touched?.dateOfBirth && formik?.errors?.dateOfBirth && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors?.dateOfBirth}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Gender *</Typography>
              <Autocomplete
                disablePortal
                id="genderId"
                name="genderId"
                options={GenderTypesListData ? GenderTypesListData : ""}
                value={
                  GenderTypesListData?.find(
                    (option) =>
                      formData?.personalInfo?.genderId === option.value
                  )?.label
                }
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      personalInfo: {
                        ...formData.personalInfo,
                        genderId: newValue?.value,
                      },
                    })
                  );
                  formik.setFieldValue("genderId", newValue);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField placeholder="Select Gender Type" {...params} />
                )}
              />
              {formik.touched?.genderId && formik.errors?.genderId && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors?.genderId}
                </FormHelperText>
              )}
            </Grid>
            <Grid item ref={maritalStatusRef} xs={12}>
              <Grid
                container
                rowGap={4}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography variant="subtitle1">Marital Status *</Typography>
                <RadioGroup
                  row
                  id="maritalStatus"
                  value={formik.values.employmentType}
                  onChange={(e) => {
                    console.log(e.target.value);
                    dispatch(
                      setFormData({
                        personalInfo: {
                          ...formData.personalInfo,
                          maritalStatus: e.target.value,
                        },
                      })
                    );
                    formik.handleChange("maritalStatus")(e.target.value);
                  }}
                  sx={{ display: "flex", gap: 4, justifyContent: "center" }}
                >
                  <FormControlLabel
                    value="Single"
                    name="maritalStatus"
                    control={<Radio sx={{ display: "none" }} />}
                    label={
                      <Box
                        sx={{
                          width: " 120px",
                          height: "116px",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                          color: "#243771",
                          border: "1px solid #000",
                          borderRadius: "20px",
                          backgroundColor:
                            formData?.personalInfo?.maritalStatus === "Single"
                              ? { backgroundColor: " #F7D64A", border: "none" }
                              : { backgroundColor: "transparent" },
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <img src={ManLogo} alt="type" />
                        <Typography sx={{ fontSize: "11px" }}>
                          Single
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="Married"
                    name="maritalStatus"
                    control={<Radio sx={{ display: "none" }} />}
                    label={
                      <Box
                        sx={{
                          width: " 120px",
                          height: "116px",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "15px",
                          color: "#243771",
                          border: "1px solid #000",
                          borderRadius: "20px",
                          backgroundColor:
                            formData?.personalInfo?.maritalStatus === "Married"
                              ? { backgroundColor: " #F7D64A", border: "none" }
                              : { backgroundColor: "transparent" },
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <img src={CoupleLogo} alt="type" />
                        <Typography sx={{ fontSize: "11px" }}>
                          Married
                        </Typography>
                      </Box>
                    }
                  />
                </RadioGroup>
              </Grid>
              {formik.touched.maritalStatus && formik.errors?.maritalStatus && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors?.maritalStatus}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Qualification *</Typography>
              <Autocomplete
                disablePortal
                id="qualificationId"
                name="qualificationId"
                options={QualificationsListData ? QualificationsListData : ""}
                onBlur={formik.handleBlur}
                value={
                  QualificationsListData?.find(
                    (option) =>
                      formData?.personalInfo?.qualificationId === option.value
                  )?.label
                }
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      personalInfo: {
                        ...formData.personalInfo,
                        qualificationId: newValue?.value,
                      },
                    })
                  );
                  formik.setFieldValue("qualificationId", newValue);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} placeholder="Please Select" />
                )}
              />
              {formik.touched?.qualificationId &&
                formik.errors?.qualificationId && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors?.qualificationId}
                  </FormHelperText>
                )}
            </Grid>
          </Grid>
          <>
            {/* <NavButton handlePrev={handlePrev} ErrorFocus={ErrorFocus} /> */}
          </>
        </Grid>
        <br />
        <br />

        {/* AddressDetails */}
        <Grid container rowGap={6}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "28px", md: "35px", lg: "36px", xl: "40px" },
              fontWeight: "900",
            }}
          >
            Address Details *
          </Typography>
          <Grid container xs={12} rowGap={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Address Line 1(Flat/Block) *
              </Typography>
              <TextField
                id="addressLine1"
                name="addressLine1"
                fullWidth
                placeholder="Enter the Address"
                value={formData?.address?.addressLine1}
                onChange={(event, newValue) => {
                  if (
                    /^(?![^A-Za-z0-9])[A-Za-z0-9\s,-/]*$/.test(
                      event.target.value
                    )
                  ) {
                    dispatch(
                      setFormData({
                        address: {
                          ...formData.address,
                          addressLine1: event.target.value,
                        },
                      })
                    );
                    formik.handleChange(event);
                  }
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched?.addressLine1 && formik.errors?.addressLine1 && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.addressLine1}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Landmark (Area/Locality) *
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                id="addressLine2"
                name="addressLine2"
                placeholder="Enter the LandMark"
                value={formData?.address?.addressLine2}
                onChange={(event, newValue) => {
                  // if (/^(?![ ]).*$/.test(event.target.value)) {
                  if (
                    /^(?![^A-Za-z0-9])[A-Za-z0-9\s,-/]*$/.test(
                      event.target.value
                    )
                  ) {
                    dispatch(
                      setFormData({
                        address: {
                          ...formData.address,
                          addressLine2: event.target.value,
                        },
                      })
                    );
                    formik.handleChange(event);
                  }
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched?.addressLine2 && formik.errors?.addressLine2 && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.addressLine2}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Pincode *</Typography>
              <TextField
                variant="outlined"
                id="pinCodeAddress"
                name="addressPinCode"
                fullWidth
                placeholder="Pincode"
                value={formData?.address?.pinCode}
                onChange={(event) => {
                  getAddress(event);
                  formik.handleChange(event);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched?.addressPinCode && formik.errors?.addressPinCode && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.addressPinCode}
                </FormHelperText>
              )}
              {PinCodeErrorData?.addressPincode ? (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {PinCodeErrorData?.addressPincode}
                </FormHelperText>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">City *</Typography>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                id="cityId"
                name="cityId"
                placeholder="City"
                value={PersonalAddressData?.city}
                onBlur={formik.handleBlur}
              />
              {/* {formik.errors?.cityId && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "14px",
                  ml: 1,
                }}
              >
                {formik.errors.cityId}
              </FormHelperText>
            )} */}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">State *</Typography>
              <TextField
                id="stateId"
                name="stateId"
                variant="outlined"
                disabled
                fullWidth
                placeholder="State"
                value={PersonalAddressData?.state}
                onBlur={formik.handleBlur}
              />
              {/* {formik.errors?.stateId && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "14px",
                  ml: 1,
                }}
              >
                {formik?.errors.stateId}
              </FormHelperText>
            )} */}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Country *</Typography>
              <TextField
                variant="outlined"
                id="countryId"
                name="countryId"
                fullWidth
                disabled
                placeholder="Country"
                value={PersonalAddressData?.country}
                onBlur={formik.handleBlur}
              />
              {/* {formik.errors?.countryId && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "14px",
                  ml: 1,
                }}
              >
                {formik.errors.countryId}
              </FormHelperText>
            )} */}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Resident Type *</Typography>
              <Autocomplete
                id="residenceTypeId"
                name="residenceTypeId"
                disablePortal
                options={residenceTypesListData ? residenceTypesListData : ""}
                value={
                  residenceTypesListData?.find(
                    (option) =>
                      formData?.address?.residenceTypeId === option.value
                  )?.label
                }
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      address: {
                        ...formData.address,
                        residenceTypeId: newValue?.value,
                      },
                    })
                  );
                  formik.setFieldValue("residenceTypeId", newValue);
                }}
                onBlur={formik.handleBlur}
                fullWidth
                renderInput={(params) => (
                  <TextField placeholder="Select Resident Type" {...params} />
                )}
              />
              {formik.touched?.residenceTypeId &&
                formik.errors?.residenceTypeId && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.residenceTypeId}
                  </FormHelperText>
                )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                How Many Years Are You Living in Your Current Address?*
              </Typography>
              <Autocomplete
                id="yearsLivingIn"
                name="yearsLivingIn"
                disablePortal
                options={Year}
                value={formData?.address?.yearsLivingIn}
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      address: {
                        ...formData.address,
                        yearsLivingIn: newValue?.value,
                      },
                    })
                  );
                  formik.setFieldValue("yearsLivingIn", newValue);
                }}
                onBlur={formik.handleBlur}
                fullWidth
                renderInput={(params) => (
                  <TextField placeholder="Please Select" {...params} />
                )}
              />
              {formik.touched?.yearsLivingIn &&
                formik.errors?.yearsLivingIn && (
                  <FormHelperText
                    sx={{
                      margin: 0,
                      color: "red",
                      fontSize: "14px",
                      ml: 1,
                    }}
                  >
                    {formik.errors.yearsLivingIn}
                  </FormHelperText>
                )}
            </Grid>
          </Grid>
          {/* <NavButton handlePrev={handlePrev} ErrorFocus={ErrorFocus} /> */}
        </Grid>
        <br />
        <br />
        {/* otherdetails */}
        <Grid container rowGap={6}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "28px", md: "35px", lg: "36px", xl: "40px" },
              fontWeight: "bold",
            }}
          >
            Other Details
          </Typography>
          <Grid container xs={12} rowGap={2}>
            <Grid
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1">
                Total EMI Pay currently Per Month:
              </Typography>
              <Box
                sx={{
                  width: "90px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "2px solid #C6E5CB",
                }}
              >
                <Typography
                  sx={{
                    color: "#5FB76D",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  ₹
                </Typography>
                <InputBase
                  id="totalEmiPaidMonthly"
                  name="totalEmiPaidMonthly"
                  sx={{ width: "80px", ml: 1, padding: 0 }}
                  value={formData?.others?.totalEmiPaidMonthly}
                  onBlur={formik.handleBlur}
                  onChange={(event, newValue) => {
                    if (/^\d*$/.test(event.target.value) && event.target.value.length <= 6) {
                      if (Number(event.target.value <= 500000)) {
                        dispatch(
                          setFormData({
                            others: {
                              ...formData.others,
                              totalEmiPaidMonthly: event.target.value,
                            },
                          })
                        )
                      formik.handleChange(event);
                    }
                  }}}
                />
              </Box>
            </Grid>
            <input
              style={{ width: "100%" }}
              id="totalEmiPaidMonthly"
              name="totalEmiPaidMonthly"
              type="range"
              value={formData?.others?.totalEmiPaidMonthly}
              onBlur={formik.handleBlur}
              onChange={(event, newValue) => {
                dispatch(
                  setFormData({
                    others: {
                      ...formData.others,
                      totalEmiPaidMonthly: event.target.value,
                    },
                  })
                );
                formik.handleChange(event);
              }}
              min="0"
              max="500000"
              step="1000"
            />
            <Grid
              container
              xs={12}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography sx={{ fontSize: "13px", color: "" }}>0</Typography>
              <Typography sx={{ fontSize: "13px", color: "" }}>
                500000
              </Typography>
            </Grid>
            {formik?.errors?.totalEmiPaidMonthly && (
              <FormHelperText
                sx={{
                  margin: 0,
                  color: "red",
                  fontSize: "14px",
                  ml: 1,
                }}
              >
                {formik?.errors?.totalEmiPaidMonthly}
              </FormHelperText>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Are you interested in getting any credit card?
              </Typography>
              <Autocomplete
                disablePortal
                id="interestedToGetCreditCard"
                name="interestedToGetCreditCard"
                options={options}
                onBlur={formik.handleBlur}
                value={
                  options?.find(
                    (option) =>
                      formData?.others?.interestedToGetCreditCard ===
                      option.value
                  )?.label
                }
                onChange={(event, newValue) => {
                  dispatch(
                    setFormData({
                      others: {
                        ...formData.others,
                        interestedToGetCreditCard: newValue?.value,
                      },
                    })
                  );
                  formik.setFieldValue("interestedToGetCreditCard", newValue);
                }}
                fullWidth
                renderInput={(params) => <TextField {...params} />}
              />
              {formik?.errors?.interestedToGetCreditCard && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik?.errors?.interestedToGetCreditCard}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          {/* <NavButton handlePrev={handlePrev} ErrorFocus={ErrorFocus} /> */}
        </Grid>
        <br />
        <br />
        {/* Submit */}
        <Grid container rowGap={6}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "28px", md: "35px", lg: "36px", xl: "40px" },
              fontWeight: "900",
            }}
          >
            Submit Loan Request
          </Typography>
          <Grid container xs={12} rowGap={1}>
            <Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Checkbox
                  name="termsAndCondition"
                  id="termsAndCondition"
                  onChange={(e) => {
                    dispatch(
                      setFormData({
                        termsAndCondition: e.target.checked,
                      })
                    );
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  checked={formData.termsAndCondition}
                />
                <Typography>
                  I hereby declare that I have read, understood and agree to the
                  {"  "}
                  <Link
                    style={{ fontWeight: "600" }}
                    to={"/terms-and-conditions"}
                  >
                    Terms & Conditions
                  </Link>
                  {"  "}
                  and the {"  "}
                  <Link style={{ fontWeight: "600" }} to={"/privacy-policy"}>
                    Privacy Policy
                  </Link>
                  .{"  "}I authorize Credmudra to receive my Credit Information
                  from CIBIL, Equifax, Experian, and CRIF regularly.{" "}
                  {Read ? (
                    <>
                      This authorization remains valid until the objective of
                      obtaining the Bureau Score for connecting with Credmudra's
                      lending partner is achieved, or up to six months from the
                      consent collection date. <br />
                      Additionally, I grant permission to Credmudra, its Lending
                      Partners, and affiliated entities to contact me for
                      various communication from Credmudra via SMS, E-mail, and
                      WhatsApp for loans, credit cards, or other relevant
                      information or promotions.{" "}
                      <Link onClick={offRead} to={""}>
                        Read Less{" "}
                      </Link>
                    </>
                  ) : (
                    <Link onClick={toggleRead} to={""}>
                      Read More{" "}
                    </Link>
                  )}
                </Typography>
              </Box>
              {formik.errors.termsAndCondition && (
                <FormHelperText
                  sx={{
                    margin: 0,
                    color: "red",
                    fontSize: "14px",
                    ml: 1,
                  }}
                >
                  {formik.errors.termsAndCondition}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <NavButton
            // handlePrev={handlePrev}
            ErrorFocus={ErrorFocus}
            isSubmit={true}
          />
        </Grid>
      </form>
      {submitLoader ? <ModalLoader /> : ""}
      {thankYouLoader ? <ThankyouModal /> : ""}
      {ContinueLoader ? <ContinueModal /> : ""}
    </>
  );
};

export default Loan;
