import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Subscribe } from "../../constant/services/credMudra";
import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <footer>
      <Box
        sx={{
          backgroundColor: "black",
          color: "#fff",
         
          marginBottom: 0,
        }}
      >
        <Box>
          <Typography sx={{ textAlign: "center", padding: "13px" }}>
            Powered By Credmudra™
          </Typography>
        </Box>
      </Box>
    </footer>
  );
};
export default Footer;
